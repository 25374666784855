import { AskADemo } from 'libs/brand-system/src/templates/AskADemo';
import { plainTextToStructuredText } from 'libs/brand-system/src/utils/structuredText';
import { Section } from 'libs/growth-platform-brand-system-v2/src/components/Section';
import { container } from 'libs/growth-platform-brand-system-v2/src/styles/themes';
import { Content } from 'libs/growth-platform-brand-system-v2/src/templates/Content';
import { HeroBlog } from 'libs/growth-platform-brand-system-v2/src/templates/HeroBlog';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { graphql } from 'gatsby';
import { morphism } from 'morphism';
import React from 'react';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import Subheader from '../../layout/subheader';
import { schemaContent } from '../../morphismSchema/templates/schemaContent';
import { schemaHeroBlog } from '../../morphismSchema/templates/schemaHeroBlog';
import { getButtons } from '../../utils/cleanProps';
import { getBlogListCtaButtons } from '../blog-home-page/logic';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="CategoryPage"
    dataName={'datoCmsCategoryPage'}
    largestContentfullPaintImg={null}
  />
);

export const query = graphql`
  query DatoCategoryPage($locale: String, $id: String) {
    datoCmsCategoryPage(locale: $locale, id: { eq: $id }) {
      ...CategoryPage
    }
    allDatoCmsSubCategoryPage(
      locale: $locale
      filter: { category: { id: { eq: $id } } }
      sort: { position: ASC }
    ) {
      nodes {
        id
        slug
        image {
          url
          alt
          gatsbyImageData
          width
          height
        }
        hero {
          title
          subtitle
        }
        trackingPageName {
          title
        }
        highlights {
          metaFields {
            title
          }
          slug
        }
      }
    }
    datoCmsBlogHome(locale: $locale) {
      slug
      trackingPageName {
        title
      }
      header {
        ...CustomMenu
      }
    }
  }
`;

const CategoryPage = ({ data, pageContext }: any) => {
  const screenSize = useScreenSize();
  const page = data.datoCmsCategoryPage;
  const homepage = data.datoCmsBlogHome;
  const hero = page?.hero?.[0];
  const subCategories = data.allDatoCmsSubCategoryPage?.nodes ?? [];
  const askADemo = page?.askADemo?.[0];

  return (
    <Layout
      pageContext={pageContext}
      data={page}
      customHeader={homepage?.header}
      templateName="CategoryPage"
    >
      <div className={classNames(container)}>
        <Subheader
          doc={page}
          hero={hero}
          slugBase={homepage.slug}
          pageContext={pageContext}
        />
      </div>

      <HeroBlog
        {...(morphism(schemaHeroBlog, {
          ...hero,
          locale: pageContext.locale,
        }) as any)}
        isCentered={true}
        locale={pageContext?.locale}
        className="mx-24 md:mx-auto"
      />

      {subCategories.map((subCategory: any, i: number) => (
        <Section
          topPadding={i === 0 ? 'full' : 'half'}
          bottomPadding={'half'}
          key={i}
        >
          <Content
            {...(morphism(schemaContent, {
              ...subCategory.hero?.[0],
              image:
                screenSize === 'xs' || screenSize === 'sm'
                  ? ''
                  : subCategory.image,

              title: plainTextToStructuredText(
                subCategory.hero?.[0]?.title || '',
              ),
              subtitle: plainTextToStructuredText(
                subCategory.hero?.[0]?.subtitle || '',
              ),
            }) as any)}
            key={subCategory.id}
            disabledAnimation={i < 1}
            backgroundColor={i % 2 === 0 ? 'white' : 'blue'}
            isEncapsulated={true}
            hasImageOnRight={i % 2 !== 0}
            buttonDirection="vertical"
          >
            {getBlogListCtaButtons({
              category: subCategory,
              locale: pageContext.locale,
              section: `${i}_Content`,
              index: i,
            })}
          </Content>
        </Section>
      ))}
      {askADemo && (
        <Section topPadding="half" bottomPadding="full">
          {askADemo.askADemoLink.map(
            (link: any, keyLink: any) =>
              link?.__typename === 'DatoCmsTemplateAskADemo' &&
              link.locale === page.locale && (
                <AskADemo
                  {...link}
                  ctas={getButtons(
                    link,
                    pageContext.locale,
                    'DatoCmsTemplateAskADemo',
                  )}
                  key={keyLink}
                />
              ),
          )}
        </Section>
      )}
    </Layout>
  );
};

export default CategoryPage;
